import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useReducer, } from "react";
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["SET_STATE"] = "SET_STATE";
    ACTIONS["CLEAR_STATE"] = "CLEAR_STATE";
})(ACTIONS || (ACTIONS = {}));
export function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.SET_STATE: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case ACTIONS.CLEAR_STATE: {
            return {
                ...state,
                ...initialState,
            };
        }
        default:
            throw new Error("Reducer action not set");
    }
}
const initialState = {
    navigationId: null,
    showFlyout: false,
};
const ctx = createContext({
    state: initialState,
    dispatch: () => {
        {
            throw new Error("dispatch called outside of Provider");
        }
    },
});
export const NavigationStateProvider = ({ children, }) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return _jsx(ctx.Provider, { value: { state, dispatch }, children: children });
};
export const useNavigationState = () => useContext(ctx);
