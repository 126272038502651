import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import { FlyoutDesktop } from "@/components/molecules";
import Link from "@/components/atoms/Link";
import { Text } from "@mm/ui";
import Arrow from "@core/svgs/arrow.svg";
import { ACTIONS as NavigationActions, useNavigationState, } from "@/utils/context/NavigationContext";
import dataLayer from "@/helper/dataLayer";
const UnorderedList = styled("ul") `
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 0;
  padding: 0;
  list-style-type: none;
`;
const Anchor = styled(Link) `
  padding-left: 0;
  padding-bottom: 20px;
  color: ${(props) => props.theme.colors.white} !important;
  font-weight: 400;

  :after {
    display: none;
  }
  :before {
    left: 0;
    top: 1.5rem;
    width: 100%;
    background: ${(props) => props.theme.colors.white};
  }
`;
const HelpSpan = styled("span") `
  height: 100%;
  position: relative;
`;
const UnstyledButton = styled("button") `
  border: none;
  background-color: transparent;
  color: #fff;
  opacity: 0;
  position: absolute;

  &:focus {
    opacity: 1;
  }

  ${"svg"} {
    size: 1rem;
    fill: ${(props) => props.theme.colors.white};
    transform: rotate(-180deg);
    transition: transform 0.3s ease;
    vertical-align: bottom;
    &.rotated {
      transform: rotate(-360deg);
    }
  }
`;
const StyledDiv = styled("div") `
  display: flex;
`;
const NavigationLink = ({ linkUrl, name, id, hasFlyout, }) => {
    const { dispatch } = useNavigationState();
    return (_jsx(Anchor, { href: linkUrl, onClick: () => {
            dispatch({
                type: NavigationActions.SET_STATE,
                payload: {
                    navigationId: id,
                    showFlyout: hasFlyout,
                },
            }),
                dataLayer({
                    eventCategory: "Top Navigation",
                    eventAction: "click",
                    eventLabel: `${name}:${linkUrl}`,
                });
        }, "data-qa": name.replace(/\s+/g, "-").toLowerCase(), onMouseEnter: () => {
            dispatch({
                type: NavigationActions.SET_STATE,
                payload: {
                    navigationId: id,
                    showFlyout: hasFlyout,
                },
            }),
                dataLayer({
                    eventCategory: "Top Navigation",
                    eventAction: "view",
                    eventLabel: `${name}`,
                });
        }, className: hasFlyout && "hasFlyout", children: _jsx(Text, { variant: "smallCopy", children: name }) }));
};
const DesktopNavigation = ({ navigation }) => {
    const { state: { navigationId, showFlyout }, dispatch, } = useNavigationState();
    return (_jsx("nav", { "aria-label": "Hauptnavigation", children: _jsx(UnorderedList, { children: navigation.map((navpoint, idx) => {
                const { id, linkUrl, name, hasFlyout, sections } = navpoint;
                return hasFlyout ? (_jsxs("li", { children: [_jsxs(StyledDiv, { children: [_jsx(NavigationLink, { linkUrl: linkUrl, name: name, id: id, hasFlyout: hasFlyout }), _jsx(HelpSpan, { children: _jsx(UnstyledButton, { type: "button", "aria-haspopup": "true", "aria-label": showFlyout
                                            ? `Menü ${name} einklappen`
                                            : `Menü ${name} ausklappen`, "aria-controls": id, "aria-expanded": navigationId ? true : false, onClick: () => {
                                            navigationId === id
                                                ? dispatch({ type: NavigationActions.CLEAR_STATE })
                                                : (dispatch({
                                                    type: NavigationActions.SET_STATE,
                                                    payload: {
                                                        navigationId: id,
                                                        showFlyout: hasFlyout,
                                                    },
                                                }),
                                                    dataLayer({
                                                        eventCategory: "Top Navigation",
                                                        eventAction: "click",
                                                        eventLabel: `Open:Flyout:${name}`,
                                                    }));
                                        }, children: _jsx(Arrow, { className: showFlyout && id === navigationId ? "rotated" : "" }) }) })] }), _jsx(FlyoutDesktop, { id: id, name: name, linkUrl: linkUrl, sections: sections, navigationId: navigationId })] }, idx)) : (_jsx("li", { children: _jsx(NavigationLink, { linkUrl: linkUrl, name: name, id: id, hasFlyout: hasFlyout }) }, idx));
            }) }) }));
};
export default DesktopNavigation;
