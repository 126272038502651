import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select } from "../index";
import styled from "styled-components";
import { Label } from "@mm/ui";
import { THEME } from "@core/theme";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
import slugify from "slugify";
const { skeletonBase } = THEME;
const Wrapper = styled.div.attrs(provideIsLoadingProp) `
  display: flex;

  position: relative;
  min-width: ${({ value }) => (value ? "20px" : "auto")};
  min-height: 56px;
  border-bottom: 2px solid;
  border-bottom-color: ${({ theme: { colors }, value }) => value ? colors.grey[5] : colors.grey[2]};
  outline: none;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-end;

  &:hover,
  &:focus,
  &:active {
    border-bottom-color: ${({ theme }) => theme.colors.grey[5]};

    svg {
      fill: ${({ theme }) => theme.colors.grey[5]};
    }
  }

  &:hover,
  &:focus,
  &:focus-within {
    label {
      top: 0;
      font-size: ${({ theme }) => theme.typography.size.s}px;
      line-height: 1;
      margin-top: ${({ theme }) => theme.space.xs}px;
      z-index: 2;
    }
  }

  svg {
    fill: ${({ theme: { colors }, value }) => value ? colors.grey[5] : colors.grey[2]};
  }

  ${({ layout, theme }) => {
    switch (layout) {
        case "error":
            return `
                    border-bottom-color: ${theme.colors.brand.primary} !important;
                `;
        case "disabled":
            return `
                    border-bottom-color: #cdcaca;
                `;
        default:
            return ``;
    }
}}

  ${({ isLoading }) => isLoading &&
    skeletonBase +
        `
      border-bottom-color: transparent;
      label, select, option {
        color: transparent;
      }
  `};
`;
const StyledInput = styled(Select) `
  border: 0;
  font-size: ${({ theme }) => theme.typography.size.m}px;
  line-height: 2;
  height: 2rem;
  color: inherit;
  width: 100%;
  min-width: 20px;
  background: transparent;
  outline: none;
  padding-right: 21px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.text};
  font-weight: 400;

  ${({ value }) => !value && "color: white;"};
`;
const Message = styled("div") `
  font-size: ${({ theme }) => theme.typography.size.s}px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.grey[4]};
  margin-top: ${({ theme }) => theme.space.xs}px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;

  ${({ layout, theme }) => {
    switch (layout) {
        case "error":
            return `
                    color: ${theme.colors.brand.primary};
                `;
        default:
            return ``;
    }
}}
`;
export const StyledSelect = ({ id, label, layout = "normal", message, value, ...props }) => {
    return (_jsxs("div", { "data-tk-cobrowse-hidden": "true", children: [_jsxs(Wrapper, { layout: layout, value: value, children: [_jsx(Label, { text: label, size: value && "small", layout: layout }), _jsx(StyledInput, { id: id ?? slugify(label), value: value, ariaDescribedBy: layout === "error"
                            ? `${id ?? slugify(label)}-error-message`
                            : undefined, ariaInvalid: layout === "error", ...props })] }), message && (_jsx(Message, { layout: layout, id: `${id ?? slugify(label)}-error-message`, "aria-live": "polite", children: message }))] }));
};
