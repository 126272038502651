import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { forwardRef, } from "react";
import classNames from "classnames";
import slugify from "slugify";
import styled from "styled-components";
const Option = styled.option `
  color: ${({ theme }) => theme.colors.grey[0]};
`;
function SelectInner({ ariaDescribedBy, ariaInvalid = false, className, defaultValue, disabled, hidePlaceholder, idField, labelField, onChange, options = [], placeholder, qaName, value, ...props }, ref) {
    const handleChange = (event) => {
        if (onChange) {
            const option = options.find((option) => {
                const id = option[idField];
                return String(id) === event.target.value;
            });
            onChange(event, option, options);
        }
    };
    return (_jsxs("select", { ...props, value: value
            ? String(typeof value === "string" ? value : value[idField])
            : undefined, onChange: handleChange, className: classNames(disabled && "disabled", className), defaultValue: defaultValue ? String(defaultValue) : undefined, disabled: disabled, "data-qa": qaName ?? "select", ref: ref, "aria-describedby": ariaDescribedBy, "aria-invalid": ariaInvalid, children: [!hidePlaceholder && (_jsx(Option, { value: "", "data-qa": "select-option", "data-tk-cobrowse-hidden": "true", children: placeholder ?? "Bitte auswählen" })), options.map((option) => {
                const label = String(typeof labelField === "function"
                    ? labelField(option)
                    : option[labelField]);
                return (_jsx(Option, { value: String(option[idField]), "data-qa": "select-option", "data-tk-cobrowse-hidden": "true", children: label }, slugify(String(option[idField]))));
            })] }));
}
export const Select = forwardRef(SelectInner);
