import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import styled from "styled-components";
import { variant as themeVariant } from "styled-system";
import "./pageHeader.scss";
import { GlobalStyles, useViewport } from "@core/theme";
import { useNavigation } from "@/utils/hooks";
import { ACTIONS as NavigationActions, useNavigationState, } from "@/utils/context/NavigationContext";
import { Brandheader } from "@/components/molecules";
const NavigationWrapper = styled("div") `
  ${themeVariant({
    scale: "navigation",
    // https://github.com/styled-system/styled-system/issues/1145#issuecomment-587389487
    variants: {
        primary: {},
    },
})}
`;
const Header = styled("header") `
  position: relative;
  ${themeVariant({
    scale: "header",
    // https://github.com/styled-system/styled-system/issues/1145#issuecomment-587389487
    variants: {
        primary: {},
    },
})}
  @media (min-width: ${(props) => props.theme.breakpointsMap.lg.min}px) {
    z-index: 20;
  }
`;
const PageOverlayPageHeader = styled.div `
  background-color: rgba(0, 0, 0, 0.4);
  inset: 0px;
  position: absolute;
  z-index: 19;
  bottom: 0;
  height: 0;
  transition: height 500ms ease;

  &.open {
    height: 100vh;
  }
`;
const PageHeader = ({ reducedHeader }) => {
    const viewport = useViewport();
    const { data: { payload: navigation = [] } = {} } = useNavigation();
    const { state: { showFlyout }, dispatch, } = useNavigationState();
    return (_jsxs(_Fragment, { children: [_jsx("a", { href: "#page-main-content", className: "skip-to-main-content-link", children: "Zum Hauptinhalt springen" }), _jsxs(Header, { variant: "primary", onMouseLeave: () => dispatch({ type: NavigationActions.CLEAR_STATE }), id: "page-header", children: [_jsx(GlobalStyles, {}), _jsx(NavigationWrapper, { variant: "primary", children: _jsx(Brandheader, { navigation: navigation || [], reducedHeader: reducedHeader }) })] }), viewport.greaterThan.lg && (_jsx(PageOverlayPageHeader, { className: showFlyout ? "open" : "" }))] }));
};
export default PageHeader;
