import React from "react";
import PropTypes from "prop-types";
import "./style.scss";

const Checkbox = ({
  checked,
  className,
  disabled,
  onClick,
  id,
  qaName,
  ariaDescribedBy,
  ariaInvalid,
}) => {
  const classNameByProp = `msd-checkbox ${
    checked ? `msd-checkbox--checked` : ""
  } ${disabled ? `msd-checkbox--disabled` : ""} ${className}`;

  return (
    <input
      type="checkbox"
      className={classNameByProp}
      id={id}
      data-qa={qaName}
      disabled={disabled}
      checked={checked}
      onClick={onClick}
      aria-describedby={ariaDescribedBy}
      aria-invalid={ariaInvalid}
    />
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  qaName: PropTypes.string,
  ariaDescribedBy: PropTypes.string,
  ariaInvalid: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  className: "",
  disabled: false,
  id: "",
  ariaInvalid: false,
};

export default Checkbox;
