import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Surface } from "@mm/ui";
import { FlyoutSections } from "@/components/molecules";
const Flyout = styled("div") `
  position: absolute;
  z-index: -10;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 0 0 2rem 2rem;
  transition: all 500ms ease;

  &.translate {
    transform: translateY(100%);
  }
`;
const LayoutDesktop = styled(Surface) `
  display: flex;
  flex-direction: row;
  padding: 3rem 0;
`;
export const FlyoutDesktop = ({ id, name, linkUrl, sections, navigationId, }) => {
    const [show, setShow] = useState(false);
    const [hide, setHide] = useState(true);
    useEffect(() => {
        if (id === navigationId) {
            setHide(false);
            const timer = setTimeout(() => setShow(true), 1);
            return () => clearTimeout(timer);
        }
        else {
            setShow(false);
            const timer = setTimeout(() => setHide(true), 501);
            return () => clearTimeout(timer);
        }
    }, [navigationId]);
    return (_jsx(Flyout, { id: id, "aria-hidden": navigationId !== id ? "true" : "false", hidden: navigationId !== id && hide, className: show ? "translate" : "", children: _jsx(LayoutDesktop, { variant: "wide", className: show ? "translate" : "", children: _jsx(FlyoutSections, { topName: name, topUrl: linkUrl, sections: sections, layoutVariant: "navDesktop" }) }) }));
};
export default FlyoutDesktop;
