import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from "styled-components";
import BaseDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Label } from "@mm/ui";
import { addDays, eachWeekendOfInterval, startOfDay } from "date-fns";
import { THEME } from "@core/theme";
import { provideIsLoadingProp, } from "@/utils/context/LoadingContext";
const { skeletonBase } = THEME;
const DatePickerWrapper = styled(({ className, ...props }) => (_jsx(BaseDatePicker, { ...props, wrapperClassName: className }))) `
  & input {
    border: 0;
    font-size: ${({ theme }) => theme.typography.size.m}px;
    line-height: 2;
    color: inherit;
    width: 100%;
    min-width: 20px;
    background: transparent;
    outline: none;
    padding-right: 21px;
    cursor: pointer;
    font-family: ${({ theme }) => theme.fonts.text};
    font-weight: 400;
  }
`;
const Popper = styled.div `
  z-index: 3;
`;
const Wrapper = styled.div.attrs(provideIsLoadingProp) `
  display: flex;

  position: relative;
  min-width: ${({ value }) => (value ? "20px" : "auto")};
  min-height: 56px;
  border-bottom: 2px solid;
  border-bottom-color: ${({ theme: { colors }, value }) => value ? colors.grey[5] : colors.grey[2]};
  outline: none;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-end;

  &:hover,
  &:focus,
  &:active {
    border-bottom-color: ${({ theme }) => theme.colors.grey[5]};

    svg {
      fill: ${({ theme }) => theme.colors.grey[5]};
    }
  }

  &:hover,
  &:focus,
  &:focus-within {
    label {
      top: 0;
      font-size: ${({ theme }) => theme.typography.size.s}px;
      line-height: 1;
      margin-top: ${({ theme }) => theme.space.xs}px;
      z-index: 2;
    }
  }

  svg {
    fill: ${({ theme: { colors }, value }) => value ? colors.grey[5] : colors.grey[2]};
  }

  ${({ layout, theme }) => {
    switch (layout) {
        case "error":
            return `
                    border-bottom-color: ${theme.colors.brand.primary} !important;
                `;
        case "disabled":
            return `
                    border-bottom-color: #cdcaca;
                `;
        default:
            return ``;
    }
}}

  ${({ isLoading }) => isLoading &&
    skeletonBase +
        `
      border-bottom-color: transparent;
      label, select, option {
        color: transparent;
      }
  `};
`;
const Message = styled("div") `
  font-size: ${({ theme }) => theme.typography.size.s}px;
  line-height: 1;
  color: ${({ theme }) => theme.colors.grey[4]};
  margin-top: ${({ theme }) => theme.space.xs}px;
  margin-bottom: ${({ theme }) => theme.space.xs}px;

  ${({ layout, theme }) => {
    switch (layout) {
        case "error":
            return `
                    color: ${theme.colors.brand.primary};
                `;
        default:
            return ``;
    }
}}
`;
export const DatePicker = ({ excludeWeekends = false, id, label, layout = "normal", message, selectableStart, selectableEnd, selected, ...props }) => {
    const start = selectableStart ?? startOfDay(new Date());
    const end = selectableEnd ?? startOfDay(addDays(new Date(), 365));
    const weekends = start && excludeWeekends ? eachWeekendOfInterval({ start, end }) : [];
    return (_jsxs("div", { children: [_jsxs(Wrapper, { layout: layout, value: selected, children: [_jsx(Label, { text: label, size: selected && "small", layout: layout }), _jsx(DatePickerWrapper, { dateFormat: "dd.MM.yyyy", includeDateIntervals: [
                            {
                                start,
                                end,
                            },
                        ], excludeDates: weekends, selected: selected, calendarStartDay: 1, popperContainer: Popper, "aria-describedby": layout === "error" ? `${id}-error-message` : undefined, "aria-invalid": layout === "error", ...props })] }), message && (_jsx(Message, { layout: layout, id: `${id}-error-message`, "aria-live": "polite", children: message }))] }));
};
